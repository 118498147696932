.vehicle-management-list {
  background: #fff;
  padding: 10px 0px 0px 10px;
  margin-bottom: 10px;
}
.vehicle-management-table {
  min-height: 600px;
  background-color: #fff;
}
.vehicle-management-detail {
  background: #fff;
  padding: 20px;
}
.vehicle-management-detail .col-left {
  background: #d8d5d5;
  text-align: center;
  padding: 3px 0;
  border: solid 1px #b9b4b4;
  border-right: none;
  border-top: none;
}
.vehicle-management-detail .col-right {
  text-align: center;
  padding: 3px 10px;
  border: solid 1px #b9b4b4;
  border-top: none;
}
.vehicle-management-detail .col-first {
  margin-top: 20px;
  border-top: solid 1px #b9b4b4;
}
.businessLine-container {
  width: 100%;
  height: auto;
  border: solid 1px #ccc;
  padding: 10px;
}
.businessLine-container .selected-businessline {
  height: auto;
  line-height: 14px;
  border: 1px solid #e8e8e8;
  background: #fafafa;
  display: inline-block;
  margin-right: 10px;
  padding: 5px;
  line-height: 20px;
}
.businessLine-container .selected-dele {
  margin-left: 10px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
}
