.cp-card {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  .cp-card-title {
    font-family: 'Arial Normal', 'Arial', sans-serif;
    margin: 8px;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    letter-spacing: normal;
    color: #333333;
    vertical-align: non;
  }
}
