.ant-breadcrumb {
  font-size: 14px;
  line-height: 22px;
  background: #fff;
  padding: 10px;
  margin: 10px 0;
}
.ant-modal-close-x {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
