body {
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  width: 100%;
  height: 100%;
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    box-shadow: inset 0 0 0 1000px #fff;
  }
}
.amap-icon img,
.amap-marker-content img{
    width: 25px;
    height: 34px;
}

.ant-carousel .slick-slide {
  text-align: center;
  background: #ccc;
  overflow: hidden;
}

