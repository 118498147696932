.acticleWrapper {
  #content {
    p {
      img {
        width: 100%;
        display: block;
      }
    }
    div {
      img {
        width: 100%;
        display: block;
      }
    }
  }
  .tableList {
    .tableListOperator {
      margin-bottom: 16px;
      button {
        margin-right: 8px;
      }
    }
  }
  .phone-box {
    width: 375px;
    height: 520px;
    background-image: url('../../assets/phone.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 316px 520px;
    white-space: pre-wrap;
    div {
      overflow: hidden;
      overflow-y: auto;
      div {
        width: 100%;
        padding-bottom: 16px;
        img {
          width: 100%;
          display: block;
        }
      }
    }
    #phone_preview_content {
      font-size: 18px;
      img,
      video {
        max-width: 100%;
      }
    }
  }
  .editor-wrapper {
    border: 1px solid #ccc;
    z-index: 100; /* 按需定义 */
    position: relative;
    width: 600px;
  }
  #editor-toolbar {
    border-bottom: 1px solid #ccc;
  }
  #editor {
    height: 500px;
  }
  .editor {
    .w-e-text-container {
      font-size: 18px;
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      table,
      pre {
        margin: 0 !important;
        line-height: 2.5 !important;
      }
      p {
        font-size: 16px !important;
      }
    }
    .w-e-toolbar .w-e-menu {
      padding: 2px !important;
    }
    .w-e-text p,
    .w-e-text h1,
    .w-e-text h2,
    .w-e-text h3,
    .w-e-text h4,
    .w-e-text h5,
    .w-e-text table,
    .w-e-text pre {
      margin: 0 !important;
      line-height: 2.5 !important;
    }
    .w-e-modal {
      line-height: 1.15;
    }
    .w-e-textarea-video-container {
      max-width: 100%;
    }
    video {
      max-width: 100%;
    }
  }

  .acticle-cover {
    & > div:nth-child(1) {
      label {
        padding-right: 20px;
      }
      > label::before {
        content: '*';
        padding-right: 5px;
        color: red;
      }
    }
  }

  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .content-style {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
.acticle-list-modal {
  > div {
    &:nth-child(1) {
      text-align: center;
      font-size: 26px;
    }
    &:nth-child(2) {
      overflow-y: auto;
      width: 375px !important;
      transform: scale(0.8) !important;
      div {
        &:nth-child(3) {
          height: 600px;
        }
        &:nth-child(4) {
          display: none;
        }
      }
    }
  }
  p {
    img {
      width: 100%;
    }
  }
  .list-title {
    font-size: 20px;
    color: #333333;
    font-weight: 800;
    margin-bottom: 8px;
  }
  .list-time {
    font-size: 12px;
    color: rgba(51, 51, 51, 0.6);
    margin-bottom: 16px;
  }
  .list-img {
    width: 323px;
    height: 194px;
    display: block;
    margin-bottom: 8px;
  }
  .size-sm {
    font-size: 18px;
    line-height: 28px;
    p {
      line-height: 28px !important;
    }
    div {
      line-height: 28px !important;
    }
  }
  .size-xl {
    font-size: 9px;
    line-height: 28px;
    p {
      line-height: 28px !important;
    }
    div {
      line-height: 28px !important;
    }
  }
}
.article-list {
  background: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
}

.spare-article-phone-box {
  width: 375px;
  height: 520px;
  background-image: url('../../assets/phone.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 316px 520px;
  white-space: pre-wrap;
  div {
    overflow: hidden;
    overflow-y: auto;
    div {
      width: 100%;
      padding-bottom: 16px;
      img {
        width: 100%;
        display: block;
      }
    }
  }
  .phone_preview_content {
    font-size: 18px;
    width: 375px;
    height: 550px;
    overflow-y: scroll;
    white-space: normal;
    word-break: break-all;
    overflow-wrap: break-word;
    line-height: 32px;
    transform: scale(0.8);
    background: rgb(255, 255, 255);
    padding: 16px;
  }
}
