.three-party-system-container {
  width: 100%;
  height: 100%;
  padding: 12px;
}
.three-party-system-container iframe {
  border: none;
  width: 100%;
  height: 100%;
}
