.acticleWrapper #content p img {
  width: 100%;
  display: block;
}
.acticleWrapper #content div img {
  width: 100%;
  display: block;
}
.acticleWrapper .tableList .tableListOperator {
  margin-bottom: 16px;
}
.acticleWrapper .tableList .tableListOperator button {
  margin-right: 8px;
}
.acticleWrapper .phone-box {
  width: 375px;
  height: 520px;
  background-image: url('../../assets/phone.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 316px 520px;
  white-space: pre-wrap;
}
.acticleWrapper .phone-box div {
  overflow: hidden;
  overflow-y: auto;
}
.acticleWrapper .phone-box div div {
  width: 100%;
  padding-bottom: 16px;
}
.acticleWrapper .phone-box div div img {
  width: 100%;
  display: block;
}
.acticleWrapper .phone-box #phone_preview_content {
  font-size: 18px;
}
.acticleWrapper .phone-box #phone_preview_content img,
.acticleWrapper .phone-box #phone_preview_content video {
  max-width: 100%;
}
.acticleWrapper .editor-wrapper {
  border: 1px solid #ccc;
  z-index: 100;
  /* 按需定义 */
  position: relative;
  width: 600px;
}
.acticleWrapper #editor-toolbar {
  border-bottom: 1px solid #ccc;
}
.acticleWrapper #editor {
  height: 500px;
}
.acticleWrapper .editor .w-e-text-container {
  font-size: 18px;
}
.acticleWrapper .editor .w-e-text-container p,
.acticleWrapper .editor .w-e-text-container h1,
.acticleWrapper .editor .w-e-text-container h2,
.acticleWrapper .editor .w-e-text-container h3,
.acticleWrapper .editor .w-e-text-container h4,
.acticleWrapper .editor .w-e-text-container h5,
.acticleWrapper .editor .w-e-text-container table,
.acticleWrapper .editor .w-e-text-container pre {
  margin: 0 !important;
  line-height: 2.5 !important;
}
.acticleWrapper .editor .w-e-text-container p {
  font-size: 16px !important;
}
.acticleWrapper .editor .w-e-toolbar .w-e-menu {
  padding: 2px !important;
}
.acticleWrapper .editor .w-e-text p,
.acticleWrapper .editor .w-e-text h1,
.acticleWrapper .editor .w-e-text h2,
.acticleWrapper .editor .w-e-text h3,
.acticleWrapper .editor .w-e-text h4,
.acticleWrapper .editor .w-e-text h5,
.acticleWrapper .editor .w-e-text table,
.acticleWrapper .editor .w-e-text pre {
  margin: 0 !important;
  line-height: 2.5 !important;
}
.acticleWrapper .editor .w-e-modal {
  line-height: 1.15;
}
.acticleWrapper .editor .w-e-textarea-video-container {
  max-width: 100%;
}
.acticleWrapper .editor video {
  max-width: 100%;
}
.acticleWrapper .acticle-cover > div:nth-child(1) label {
  padding-right: 20px;
}
.acticleWrapper .acticle-cover > div:nth-child(1) > label::before {
  content: '*';
  padding-right: 5px;
  color: red;
}
.acticleWrapper .avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.acticleWrapper .content-style {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.acticle-list-modal > div:nth-child(1) {
  text-align: center;
  font-size: 26px;
}
.acticle-list-modal > div:nth-child(2) {
  overflow-y: auto;
  width: 375px !important;
  transform: scale(0.8) !important;
}
.acticle-list-modal > div:nth-child(2) div:nth-child(3) {
  height: 600px;
}
.acticle-list-modal > div:nth-child(2) div:nth-child(4) {
  display: none;
}
.acticle-list-modal p img {
  width: 100%;
}
.acticle-list-modal .list-title {
  font-size: 20px;
  color: #333333;
  font-weight: 800;
  margin-bottom: 8px;
}
.acticle-list-modal .list-time {
  font-size: 12px;
  color: rgba(51, 51, 51, 0.6);
  margin-bottom: 16px;
}
.acticle-list-modal .list-img {
  width: 323px;
  height: 194px;
  display: block;
  margin-bottom: 8px;
}
.acticle-list-modal .size-sm {
  font-size: 18px;
  line-height: 28px;
}
.acticle-list-modal .size-sm p {
  line-height: 28px !important;
}
.acticle-list-modal .size-sm div {
  line-height: 28px !important;
}
.acticle-list-modal .size-xl {
  font-size: 9px;
  line-height: 28px;
}
.acticle-list-modal .size-xl p {
  line-height: 28px !important;
}
.acticle-list-modal .size-xl div {
  line-height: 28px !important;
}
.article-list {
  background: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
}
.spare-article-phone-box {
  width: 375px;
  height: 520px;
  background-image: url('../../assets/phone.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 316px 520px;
  white-space: pre-wrap;
}
.spare-article-phone-box div {
  overflow: hidden;
  overflow-y: auto;
}
.spare-article-phone-box div div {
  width: 100%;
  padding-bottom: 16px;
}
.spare-article-phone-box div div img {
  width: 100%;
  display: block;
}
.spare-article-phone-box .phone_preview_content {
  font-size: 18px;
  width: 375px;
  height: 550px;
  overflow-y: scroll;
  white-space: normal;
  word-break: break-all;
  overflow-wrap: break-word;
  line-height: 32px;
  transform: scale(0.8);
  background: #ffffff;
  padding: 16px;
}
