.ant-breadcrumb {
  font-size: 14px;
  line-height: 22px;
  background: #fff;
  padding: 10px;
  margin: 10px 0;
}
.ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.45);
}
.global-form-container {
  background-color: #fff;
  min-height: 600px;
  padding: 20px;
}
